import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import Layout from '@/components/Layout';
import ServiceHero from '@/components/ServiceHero';
import ServiceLinkItem from '@/components/ServiceLinkItem';

import { SERVICE_TRANSITION } from '@/referentials/routes';

export default function GrowthHackingPage({ data }) {
  const { t } = useTranslation();

  const contentGrowth = {
    name: t('growthTitle'),
    bg: 'bg-orange',
    blobFirstColor: '#F0BDB5',
    blobSecondColor: '#FECBC3',
    content: t('growthContent'),
    nextPage: t('growthNextPage'),
    tags: [t('tagGrowth'), t('tagSeo'), t('tagSea'), t('tagSmo')],
  };
  return (
    <Layout
      title="Services: Growth Hacking"
      description="Le charrette.club design, code et fait grandir des produits digitaux innovants."
      className="mt-8"
    >
      <ServiceHero tags={contentGrowth} img={data.growth} />
      <ServiceLinkItem
        title={`${t('next')} ${t('digitalTitle')}`}
        to={SERVICE_TRANSITION}
      />
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["services", "general"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    growth: file(relativePath: { eq: "growth-hacking.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`;
